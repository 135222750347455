import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from '@hooks/useDebounce';
import { useGetPatientInfo, useUpdatePatientInfo } from '@query/StudyQuery';
import { PatientInfo, PatientRequest } from '@UserTypes/StudyTypes';
import { MdOutlineSearchOff as SearchOff} from "react-icons/md"
import { FaCirclePlus,FaCircleMinus  } from "react-icons/fa6";
import '../assets/css/common/mobile.css'
import { Toast } from '@lib/Toas';
import { FaSearch } from 'react-icons/fa';

export default function Mobile(){
    const { register, handleSubmit, setValue, watch, formState } = useForm<PatientRequest>({
        defaultValues: {
            patient_idx: null,
            info_memo: "",
            info_files: [],
        },
    });
    const [fileList, setFileList] = useState<Map<number, File|null>>(new Map([[0,null]]));
    const [selectedPatient, setSelectedPatient] = useState<PatientInfo|null>(null);
    const [searchVal, setSearchVal] = useState<string>('');
    const searchParam  = useDebounce(searchVal, 500);
    const {data}=useGetPatientInfo({patient_name:searchParam});
    const updatePatientInfo = useUpdatePatientInfo();
    const onSearchPatient = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSearchVal(e.currentTarget.value);
        setSelectedPatient(null);
    };

    const onSelectPatient = (info:PatientInfo) => {
        setSelectedPatient(info);
        setSearchVal(info.patient_name);
        setValue("patient_idx", info.patient_idx);
    }

    const onFileUpload= (e:React.ChangeEvent<HTMLInputElement>, index:number) => {
        if(e.target.files){
            const file = e.target.files[0];
            setFileList((prev) => {
                const updatedMap = new Map(prev);
                updatedMap.set(index, file);
                return updatedMap;
            });
    
            const prevFiles = watch("info_files") || [];
            let updatedFiles = [...prevFiles];

            if (index < updatedFiles.length) {
                updatedFiles[index] = file;
            } else {
                updatedFiles.push(file);
            }

            setValue("info_files", updatedFiles);
            
        }
    };

    const onAddFiles = () => {
        setFileList((prev) => {
            const newIndex = prev.size;
            return new Map(prev).set(newIndex, null);
        });
    }

    const onDelFiles = (index:number) => {
        setFileList((prev) => {
            if (prev.size <= 1) {
                return prev;
            }
            const updatedMap = new Map(prev);
            updatedMap.delete(index);

            const reorderedMap = new Map();
            Array.from(updatedMap.values()).forEach((file, i) => {
                reorderedMap.set(i, file);
            });

            setValue("info_files", Array.from(reorderedMap.values()).filter(file => file !== null));
            return reorderedMap;
        });

    }

    const onSubmit = handleSubmit((data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (value === null || value === undefined) {
                return;
            }
            if(key === 'info_files'){
                value.map((file:File)=>{
                    formData.append(key, file);
                });
            }else{
                formData.append(key, value);
            }
            
        });
        updatePatientInfo.mutate(formData,{
            onSuccess() {
                Toast('저장 완료', 'success');
                const defaultValue = formState.defaultValues as PatientRequest
                Object.entries(defaultValue).forEach(([key, value]) => {
                    setValue(key as keyof PatientRequest, value); 
                });
                setFileList(new Map([[0,null]]));
                setSelectedPatient(null)
                setSearchVal('')
            },
            onError(){
                Toast('저장 실패', 'error')
            }
        });
    });

    return(
            <div className="mobile_wrap">
                <form onSubmit={onSubmit}>
                <div className="search">
                    {/* <h3>환자명</h3> */}
                    <div className='search_box'>
                        <input 
                            type="text" 
                            placeholder='환자명 또는 환자ID를 입력하세요...'
                            value={selectedPatient? `${selectedPatient.patient_name}(${selectedPatient.patient_id})` : searchVal} 
                            onChange={onSearchPatient}
                        />
                        {searchVal&& !selectedPatient && data?.patient_list && data.patient_list.length > 0 && (
                            <div className='result_list'>
                                <ul>
                                    {data.patient_list.map((list)=>(
                                        <li key={`${list.patient_idx}`} onClick={()=> onSelectPatient(list)}>
                                            <em>{`${list.patient_name}(${list.patient_id})`}</em>
                                            <span>{`${list.patient_birthday}`}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {searchVal && !selectedPatient && data?.patient_list?.length === 0 && (
                            <div className='no_results'>
                                <SearchOff style={{width:'38px', height:'38px'}}/>
                                <p><strong>"{searchVal}"</strong>에 대한 검색 결과가 없습니다.</p>
                            </div>
                        )}
                    </div>
                    {/* <button className="btn_basic btn_search" >검색</button> */}
                </div>
                <div className="comment">
                    <h3>코멘트</h3>
                    <textarea  {...register("info_memo")}></textarea>
                </div>
                <div className="file_upload">
                    <div className="flexbox spbetween">
                        <h3>파일업로드</h3>
                        <div className="btn">
                            <button type="button"  onClick={()=> onAddFiles()}><FaCirclePlus/></button>
                            {/* <button><FaCircleMinus/></button> */}
                        </div>
                    </div>
                    <div className="uploadbox">
                        {Array.from(fileList.keys()).map((value, index) => ( 
                            <div key={fileList.get(index)?.name||index}>
                                <label className="upload_label">
                                    <p>파일 선택</p>
                                    <input type="file" accept='image/*' onChange={(e) =>{console.log(value, index); onFileUpload(e, index); }} />
                                </label>
                                <p className='file_name'>{fileList.get(index)?.name || "파일을 선택하세요."}</p>
                                <button type="button" disabled={fileList.size === 1} onClick={() => onDelFiles(index)}>
                                    <FaCircleMinus />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="btn flexbox spcenter">
                    <button className="btn_basic blue flex1" type='submit'>저장</button>
                </div>
                </form>
            </div>
    )
}