import client from '@lib/axios';
import { assignStudyParamsType, studyParamsType } from '@service/types/study';
import {
    CenterResponse,
    DoctorParamasType,
    DoctorResponse,
    HospitalParamsType,
    HospitalResponse, HospitalUserParamsType, HospitalUserResponse, ModalityResponse, PatientParam, PatientRequest, PatientResponse, ReportHistryResponse, StudyResponse
} from "@UserTypes/StudyTypes";

export default class StudyService {
    /* 스터디 호출 */
    public static async getStudy(params:studyParamsType):Promise<StudyResponse> {
        return await client.get('/study/', {params: params})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }
    /* 레포트 호출 */
    public static async getReport(studyIdx:string):Promise<any> {
        return await client.get(`/report/${studyIdx}`)
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }
    /* 모달리티 호출 */
    public static async getModalityList():Promise<ModalityResponse> {
        return await client.get('/study/modality')
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }
    /* 바디파트 호출 */
    public static async getBodyPartList():Promise<any> {
        return await client.get('/study/bodyPart')
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }

    /* 판독의 리스트 호출 */
    public static async getDoctorList(params:DoctorParamasType):Promise<DoctorResponse> {
        return await client.get('/doctor/', {params:params})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }
    /* 판독센터 호출 */
    public static async getCenterList():Promise<CenterResponse> {
        return await client.get('/center/')
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }
    /* 병원리스트 호출 */
    public static async getHospitalList(params:HospitalParamsType):Promise<HospitalResponse> {
        return await client.get('/hospital/',{params:params})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }

    public static async getHospitalUsers(params:HospitalUserParamsType):Promise<HospitalUserResponse> {
        //병원 담당자리스트
        return await client.get('/hospital/user', {params: params})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }

    /* 스터디 응급 변경 */
    public static async updateStudyChangeER(studyIdx:string):Promise<any> {
        return await client.post(`/study/emergency`, {study_idx: studyIdx})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }
    /* 스터디 배정 */
    public static async setStudyAssign(data:assignStudyParamsType):Promise<any> {
        return await client.post('/study/assign', data)
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }
    /* 스터디 코멘트 추가 */
    public static async updateStudyComment(data:{study_idx:number, comment_content: string}):Promise<any> {
        return await client.post(`/study/comment`, data)
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }
    /* 레포트 히스토리 */
    public static async getStudyReportHistory(params:{study_idx?:number}):Promise<ReportHistryResponse> {
        return await client.get('/study/history', {params: params})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }

    /* 환자정보 리스트 */
    public static async getPatientInfo(params:PatientParam):Promise<PatientResponse> {
        return await client.get('/patient/', {params: params})
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }
    /* 의료기록지 업로드 */
    public static async updatePatientInfo(data:PatientResponse):Promise<any> {
        return await client.postForm(`/patient/`, data)
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }

}
