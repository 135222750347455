import StudyService from "@service/api/StudyService";
import { assignStudyParamsType, PatientParams, studyParamsType } from "@service/types/study";
import { useMutation,  useQuery, useQueryClient } from "@tanstack/react-query";
import {DoctorParamasType, DoctorResponse, HospitalParamsType, PatientParam, PatientRequest} from "@UserTypes/StudyTypes";

export const useStudyQuery = (params:studyParamsType) =>
    useQuery({
        queryKey: ['GET_STUDY', params],
        queryFn: async () => {
            return await StudyService.getStudy(params);
        },
        refetchOnMount:false,
        enabled: !!params,
    });
export const useReportQuery = (studyIdx:string) => 
    useQuery({
        queryKey: ['GET_REPORT', studyIdx],
        queryFn: async () => {
            return await StudyService.getReport(studyIdx);
    },
    }); 
export const useDoctorQuery = (params:DoctorParamasType) => 
    useQuery<DoctorResponse>({
      queryKey: ['GET_STUDY_DOCTOR',params],
      queryFn: async () => {
        return StudyService.getDoctorList(params);
      },
      refetchOnMount:true,
      
    });
export const useCenterQuery = () => 
    useQuery({
        queryKey: ['GET_STUDY_CENTER'],
        queryFn: async () => {
        return await StudyService.getCenterList();
        },
    });
export const useHospitalQuery = (params:HospitalParamsType) => 
    useQuery({
        queryKey: ['GET_STUDY_HOSPITAL', params],
        queryFn: async () => {
        return await StudyService.getHospitalList(params);
        },
    });
export const useModalityQuery = () => 
    useQuery({
        queryKey: ['GET_STUDY_MODALITY'],
        queryFn: async () => {
        return await StudyService.getModalityList();
        },
    });

export const useStudyReportHistory = (params:{study_idx?:number}) => 
        useQuery({
            queryKey: ['GET_STUDY_REPORT_HISTORY',params],
            queryFn: async () => {
            return await StudyService.getStudyReportHistory(params);
            },
            enabled: !!params.study_idx,
    });
export const useUpdateStudyER = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['UPDATE_STUDY_ER'], 
        mutationFn: async (studyIdx:string) => {
            return await StudyService.updateStudyChangeER(studyIdx);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey:['GET_STUDY']})
        },
    });
}

export const useStudyAssignMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['STUDY_ASSIGN'], 
        mutationFn: async (data:assignStudyParamsType) => {
            return await StudyService.setStudyAssign(data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey:['GET_STUDY']})
        },
    });
};
export const useCommentUpdateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['STUDY_COMMENT_UPDATE'], 
        mutationFn: async (data:{study_idx:number, comment_content:string}) => {
            return await StudyService.updateStudyComment(data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey:['GET_STUDY']})
            queryClient.invalidateQueries({ queryKey:['GET_STUDY_REPORT_HISTORY']})
            
        },
    });
};

export const useGetPatientInfo = (param:PatientParam) => 
    useQuery({
        queryKey: ['GET_PATIENT_INFO',param],
        queryFn: async () => {
        return await StudyService.getPatientInfo(param);
        },
        enabled: !!param.patient_name
    });

export const useUpdatePatientInfo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['UPDATE_PATIENT_INFO'], 
        mutationFn: async (data:any) => {
            return await StudyService.updatePatientInfo(data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey:['GET_PATIENT_INFO']})
        },
    });
};
