import React, {useEffect, useState} from "react";
import {useQueries} from "@tanstack/react-query";
import StudyService from "@service/api/StudyService";
import {useListAction} from "@store/study/store";
import {useUserData} from "@store/user/store";
import jsonData  from "@component/manage/hospital/hospitalTypeCode.json"

type WrapperProps = {
  children: any;
}

export  default function ConfigProvider({children}: WrapperProps){
  const token = useUserData(state => state.token)
  const [isLoading, setIsLoading] = useState(!token);
  const {setDoctorList, setCenterList, setHospitalList, setModalityList, setBodyPartList} = useListAction();
  const combinedQueries  = useQueries({
    queries:[
      { queryKey: ["GET_STUDY_DOCTOR"], queryFn:  ()=> StudyService.getDoctorList({}), enabled: !!token },
      { queryKey: ["GET_STUDY_CENTER"], queryFn:  ()=> StudyService.getCenterList(), enabled: !!token },
      { queryKey: ["GET_STUDY_HOSPITAL"], queryFn:  ()=> StudyService.getHospitalList({}), enabled: !!token},
      { queryKey: ["GET_STUDY_MODALITY"], queryFn:  ()=> StudyService.getModalityList(), enabled: !!token},
      { queryKey: ["GET_STUDY_BODYPART"], queryFn:  ()=> StudyService.getBodyPartList(), enabled: !!token},
    ],
    combine: results => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      }
    }
  }); //병원, 센터, 판독의, 모달리스트는 여러페이지에서 공통으로 쓰이니 redux에 담아두기
  useEffect(() => {
    if(!combinedQueries.pending){
      const results = combinedQueries.data
      results.map(item => {
        if(item){
          if('doctor_data' in item) setDoctorList(item.doctor_data)
          if('center_data' in item) setCenterList(item.center_data)
          if('hospital_data' in item) setHospitalList(item.hospital_data)
          if('modality_data' in item){
            setModalityList(item.modality_data)
          } else {
            setModalityList(jsonData.modalityDefault)
          } 
          if('body_part_list' in item) setBodyPartList(item.body_part_list)
        }
      })
      setIsLoading(true)
    }
  }, [combinedQueries]);
  return (
    <>{isLoading && children}</>
  )
}