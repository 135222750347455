import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import Header from "@pages/workList/Header";
import {menuList, menuListType} from "@layout/menu";
import classNames from "classnames";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import { memo } from "@tanstack/react-table";
import { getUserData } from "@store/user/store";

export default function Layout({child}:{child?:JSX.Element}) {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {userData} = getUserData();

    /* 사용자 그룹  
        1: 전체관리자
        2: 의사
        3: 센터
        4: 병원
        5: 영업담당자
    */
    const getAccessibleMenu = (groupIdx: number|undefined, menuList: menuListType[]): menuListType[] => {
        const accessibleIds = (() => {
            switch (groupIdx) {
                case 1:
                    return [
                        "reading", "payment","hospital", "doctor", "center", "adminhospital", "fee", "commission", "user", "statistics",
                         "s_hospital", "s_doctor", "s_center", "s_sales","upload"
                    ];
                case 2:
                    return ["doctor","s_doctor"];
                case 3:
                    return ["reading", "payment","hospital" ,"doctor", "center", "adminhospital", "commission", , "fee", "s_center", ];
                case 4:
                    return ["reading", "payment", "s_hospital", "hospital",];
                case 5:
                    return ["s_sales","upload"];
                default:
                    return [];
            }
        })();
    
        const filterMenu = (menu: menuListType[]): menuListType[] => {
            return menu.map((item) => {
                const isAccessible = accessibleIds.includes(item.id);
                const filteredChildren = item.children ? filterMenu(item.children) : undefined; // childern도 accessibleIds 검사
                const isAnyChildEnabled = filteredChildren?.some(child => !child.disabled) || false; // childern내에 arrow 있으면 부모도 동기화
                return {    
                    ...item,
                    url: filteredChildren && isAnyChildEnabled? filteredChildren.find(v => !v.disabled)?.url || item.url: item.url,
                    disabled: filteredChildren? (!isAccessible && !isAnyChildEnabled) :!isAccessible ,
                    children: filteredChildren,
                };
            });
        };
    
        return filterMenu(menuList);
    };
    const memoizedMenuList = useMemo(() => {
        const accessibleMenuList = getAccessibleMenu(userData?.group_idx, menuList);
        return accessibleMenuList;
    }, [userData]);

    const handleMenuNavigation = useCallback((item:menuListType) => {
        return (e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            navigate(item.url); // 페이지 이동
        };
    }, [navigate]);
    // const [isVisible, setIsVisible] = useState<boolean>(true);
    // useEffect(() => {
    //     setIsVisible(false); // Fade-out
    //     const timeout = setTimeout(() => setIsVisible(true), 300); // Fade-in
    //     return () => clearTimeout(timeout);
    // }, [pathname]);
    return (
        <div className="layout_wrap">
            <Header/>
            <div className="side_menu_wrap">
                <ul id="menu_list">
                    {memoizedMenuList.filter(list=> list.disabled === false).map(item => {
                        const childrens = item?.children?.map(val => val.url) || []
                        return(
                        <li key={item.id}>
                            <Link
                                to={item.url}
                                onClick={handleMenuNavigation(item)}
                                className={classNames({active: item.children? childrens.includes(pathname): pathname.includes(item.url)})}>
                                <item.icon/>
                                <div className="menu_item_title">{item.name}</div>
                                {item.children && (
                                    <div className="menu_item_arrow">
                                        { pathname.includes(item.id) ? <IoIosArrowUp/> :<IoIosArrowDown/> }
                                    </div>
                                )}
                            </Link>
                            {item.children && (
                                <div className={classNames('sub_menu_wrap', {active: item.url === pathname || childrens.includes(pathname)})}>
                                    <ul className="menu_sub_list">
                                        {item.children.filter(clist => clist.disabled === false).map(child => {
                                            return (
                                                <li key={child.id}>
                                                    <Link
                                                        to={child.url}
                                                        onClick={handleMenuNavigation(child)}
                                                        className={classNames({active: child.url === pathname})}>
                                                        <child.icon/>
                                                        <div className="menu_item_title">{child.name}</div>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    )})}
                    {/* {memoizedMenuList.map(item => {
                        const childrens = item?.children?.map(val => val.url) || []
                        return(
                        <li key={item.id}>
                            <Link
                                to={item.url}
                                onClick={handleMenuNavigation(item)}
                                className={classNames({active: pathname.includes(item.id)})}>
                                <item.icon/>
                                <div className="menu_item_title">{item.name}</div>
                                {item.children && (
                                    <div className="menu_item_arrow">
                                        { pathname.includes(item.id) ? <IoIosArrowUp/> :<IoIosArrowDown/> }
                                    </div>
                                )}
                            </Link>
                            {item.children && (
                                <div className={classNames('sub_menu_wrap', {active: item.url === pathname || childrens.includes(pathname)})}>
                                    <ul className="menu_sub_list">
                                        {item.children.map(child => {
                                            return (
                                                <li key={child.id}>
                                                    <Link
                                                        to={child.url}
                                                        onClick={handleMenuNavigation(child)}
                                                        className={classNames({active: child.url === pathname})}>
                                                        <child.icon/>
                                                        <div className="menu_item_title">{child.name}</div>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    )})} */}
                </ul>
            </div>
            <div className={`contents_wrap`}>
                {child?child:<Outlet/>}
            </div>
        </div>

    )
}
