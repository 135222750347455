import React, {lazy, Suspense,useTransition, useMemo, useState, useEffect} from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import { BrowserView, MobileView, } from 'react-device-detect';
import '@hoc/Auth'
import '@assets/css/common/default.css'
import '@assets/css/common/common.css'
import '@assets/css/common/fadeEffect.css'
import Login from "@pages/user/Login";
import Auth from "@hoc/Auth";
import Layout from "@layout/Layout";
import ManageLayout from '@pages/manage/ManageLayout';
import { getUserData, useUserData } from '@store/user/store';
import Mobile from "@pages/Mobile";
import MobileLayout from '@layout/MobileLayout';
import useMobile from '@hooks/useMobile';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';


function App() {
    const userData = useUserData((state)=> state.userData);
    const LoginPage = Auth(Login, false, false)
    const LayoutPage = Auth(Layout, true, false)
    const MobileLayoutPage = Auth(MobileLayout, true, false)
    const ManageLayoutPage = Auth(ManageLayout, true, false)
    const ReadingPage = lazy(() => import("@component/reading/ReadingList"));
    const PayHosPage = lazy(() => import("@component/pay/PayHospitalList"));
    const PayDocPage = lazy(() => import("@component/pay/PayDoctorList"));
    const NotFoundPage = lazy(() => import("@pages/error/NotFound"));
    const MobileWarnPage = lazy(() => import("@pages/error/MobileWarn"));
    const CenterPage = lazy(() => import("@pages/manage/Center"));
    const ManagePayPage = lazy(() => import("@component/manage/PayManage"));
    const ManageCenterPage = lazy(() => import("@component/manage/center/CenterManage"));
    const ManageHospitalPage = lazy(() => import("@component/manage/hospital/HospitalManage"));
    const UserPage = lazy(() => import("@component/manage/user/UserPage"));
    const CommissionPage =  lazy(() => import("@component/manage/commission/CommissionPage"));
    const StatisticsDoctor = lazy(() => import("@component/statistics/SDoctor"));
    const StatisticsHospital = lazy(() => import("@component/statistics/SHospital"));
    const StatisticsCenter = lazy(() => import("@component/statistics/SCenter"));
    const StatisticsManager = lazy(() => import("@component/statistics/SManager"));

    const groupIdx = useMemo(() => (userData?.group_idx), [userData]);
    const isMobile = useMobile();
    useEffect(()=>{
      console.log(`demo.ver.2025.03.11.1`, isMobile, groupIdx)
    },[])
    const getAccessibleRoutes = (groupIdx:number|undefined) => {
      if(groupIdx){
        switch (groupIdx) {
          case 1:
            return (
              <Route path='/' element={<LayoutPage/>}>
                <Route index element={<ReadingPage/>}/>
                <Route path='reading' element={<ReadingPage/>}/>
                <Route path='payment'>
                    <Route path='hospital' element={<PayHosPage/>}/>
                    <Route path='doctor' element={<PayDocPage/>}/>
                </Route>
                <Route path='manage' element={<ManageLayout/>}>
                    <Route path='commission' element={<CommissionPage/>}/>
                    <Route path='user' element={<UserPage/>}/>
                    <Route path='hospital' element={<ManageHospitalPage/>}/>
                    <Route path='center' element={<ManageCenterPage/>}/>
                    <Route path='fee' element={<ManagePayPage/>}/>
                </Route>
                <Route path='statistics' element={<ManageLayout/>}>
                    <Route path='doctor' element={<StatisticsDoctor/>}/>
                    <Route path='hospital' element={<StatisticsHospital/>}/>
                    <Route path='center' element={<StatisticsCenter/>}/>
                    <Route path='sales' element={<StatisticsManager/>}/>
                </Route>
                <Route path='upload' element={<div style={{padding:'20px'}}><Mobile/></div>}/>
              </Route>);
          case 2:
            return (
              <Route path='/' element={<LayoutPage/>}>
                <Route index element={<PayDocPage/>}/>
                <Route path='payment'>
                    <Route path='doctor' element={<PayDocPage/>}/>
                </Route>
                <Route path='statistics' element={<ManageLayout/>}>
                    <Route path='doctor' element={<StatisticsDoctor/>}/>
                </Route>
              </Route>
              );
          case 3:
            return (
              <Route path='/' element={<LayoutPage/>}>
                <Route index element={<ReadingPage/>}/>
                <Route path='reading' element={<ReadingPage/>}/>
                <Route path='payment'>
                    <Route path='hospital' element={<PayHosPage/>}/>
                    <Route path='doctor' element={<PayDocPage/>}/>
                </Route>
                <Route path='manage' element={<ManageLayout/>}>
                    <Route path='commission' element={<CommissionPage/>}/>
                    <Route path='center' element={<ManageCenterPage/>}/>
                    <Route path='hospital' element={<ManageHospitalPage/>}/>
                    <Route path='fee' element={<ManagePayPage/>}/>
                </Route>
                <Route path='statistics' element={<ManageLayout/>}>
                    <Route path='center' element={<StatisticsCenter/>}/>
                </Route>
              </Route>
            );
          case 4:
            return (
              <Route path='/' element={<LayoutPage/>}>
                <Route index element={<ReadingPage/>}/>
                <Route path='reading' element={<ReadingPage/>}/>
                <Route path='payment'>
                    <Route path='hospital' element={<PayHosPage/>}/>
                </Route>
                <Route path='manage' element={<ManageLayout/>}>
                    <Route path='hospital' element={<ManageHospitalPage/>}/>
                </Route>
                <Route path='statistics' element={<ManageLayout/>}>
                  <Route path='hospital' element={<StatisticsHospital/>}/>
                </Route>
              </Route>
            );
          case 5:
            return (
              <Route path='/' element={<LayoutPage child={<ManageLayout/>}/>}>
                <Route index element={<StatisticsManager/>}/>
                <Route path='/statistics/sales' element={<StatisticsManager/>}/>
                <Route path='upload' element={<div style={{padding:'20px'}}><Mobile/></div>}/>
              </Route>
            );
        }
      }else{
        return (<Route path="/" element={<LoginPage/>} />)
      }
       
    };
 
    const getAccessibleRoutesMobile = (groupIdx:number|undefined) => {
      if(groupIdx){
        switch (groupIdx) {
          case 2: 
          case 3:
          case 4:
            return (
              <Route path='/' element={<MobileLayoutPage/>}>
                <Route index element={<MobileWarnPage/>}/>
              </Route>
          );
          case 1:
          case 5:
            return (
              <Route path='/' element={<MobileLayoutPage/>}>
                <Route index element={<Mobile/>}/>
                <Route path='upload' element={<Mobile/>}/>
                <Route path='/statistics/sales' element={<MobileWarnPage/>}/>
                <Route path="*" element={<MobileWarnPage />} />
              </Route>
            );
        }
      }else{
        return (<Route path="/" element={<LoginPage/>} />)
      }
    };

  return (
   <Suspense fallback={null}>
      {isMobile ?
        <MobileView>
            <Routes>
              {getAccessibleRoutesMobile(groupIdx)}
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </MobileView>
        :
        <BrowserView>
          <Routes>
            {getAccessibleRoutes(groupIdx)}
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserView>
      }
   </Suspense>
  );
}

export default App;
        {/* {hasAccess(accessibleRoutes) && (
            <Route path='/' element={<LayoutPage/>}>
                <Route index element={LayoutPageWithDefaultRoute(userData?.group_idx)}/>
                <Route path='reading' element={<ReadingPage/>}/>
                <Route path='payment'>
                    <Route path='hospital' element={<PayHosPage/>}/>
                    <Route path='doctor' element={<PayDocPage/>}/>
                </Route>
                <Route path='manage' element={<ManageLayout/>}>
                    <Route path='commission' element={<CommissionPage/>}/>
                    <Route path='user' element={<UserPage/>}/>
                    <Route path='hospital' element={<ManageHospitalPage/>}/>
                    <Route path='center' element={<ManageCenterPage/>}/>
                    <Route path='fee' element={<ManagePayPage/>}/>
                    <Route path='test' element={<ManagePayTestPage/>}/>
                </Route>
                <Route path='statistics' element={<ManageLayout/>}>
                    <Route path='doctor' element={<StatisticsDoctor/>}/>
                    <Route path='hospital' element={<StatisticsHospital/>}/>
                    <Route path='center' element={<StatisticsCenter/>}/>
                    <Route path='sales' element={<StatisticsManager/>}/>
                </Route>
            </Route>
         )} */}