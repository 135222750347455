import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * 토스트 메세지 띄우기
 * https://fkhadra.github.io/react-toastify/introduction
 * @param text 메세지
 * @param type 구분(info,success,warn,error)
 * @param position 위치(top-left,top-right,top-center,bottom-left,bottom-right,bottom-center)
 * @param duration 지속시간
 * @param theme 테마(light,dark,colored)
 * @param icon 아이콘 사용
 * @constructor
 */
export const Toast = (
  text: string = '',
  type: 'info' | 'success' | 'warning' | 'error' | 'default' = 'default',
  position:
        | 'top-right'
        | 'top-center'
        | 'top-left'
        | 'bottom-right'
        | 'bottom-center'
        | 'bottom-left' = 'top-right',
  duration: number = 1800,
  theme: 'light' | 'dark' | 'colored' = 'dark',
  icon: any = true,
) => {
  toast(text, {
    type,
    position,
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: '',
    style: { backgroundColor: '#181c20', fontSize: 14,  whiteSpace: 'pre-line' },
    theme,
    icon,
  });
};
