import React, { useEffect, useState } from 'react'

const useMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
      const checkMobile = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const mobileKeywords = ["iphone", "android", "webos", "blackberry", "windows phone"];
        const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));
        setIsMobile(isMobileDevice);
      };
  
      // 초기 체크
      checkMobile();
  
      // 화면 크기 변경 시 다시 체크
      window.addEventListener("resize", checkMobile);
      
      return () => {
        window.removeEventListener("resize", checkMobile);
      };
    }, []);
  
    return isMobile;
}

export default useMobile
