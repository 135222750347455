import { createStore } from "@store/store";
import { SelectorHook } from "@store/type";
export interface listInitialState{
    hospitalList: any[]
    doctorList: any[]
    centerList: any[]
    modalityList: any[]
    bodyPartList: any[]
    isLoading: boolean;
    actions: {
        setHospitalList: (list: any[])=>void,
        setDoctorList: (list: any[])=>void,
        setCenterList: (list: any[])=>void,
        setModalityList: (list: any[])=>void,
        setBodyPartList: (list: any[])=>void,
        setLoading: (loading: boolean) => void;
    }
}
export const useListStore = createStore<listInitialState>((set)=>({
    hospitalList: [],
    doctorList: [],
    centerList: [],
    modalityList:[],
    bodyPartList:[],
    isLoading:true,
    actions:{
        setHospitalList: (list:any[]) => { set((state) => { state.hospitalList = list; }); },
        setDoctorList: (list:any[]) => { set((state) => { state.doctorList = list; }); },
        setCenterList: (list:any[]) => { set((state) => { state.centerList = list; }); },
        setModalityList: (list:any[]) => { set((state) => { state.modalityList = list; }); },
        setBodyPartList: (list:any[]) => { set((state) => { state.bodyPartList = list; }); },
        setLoading: (loading) => set({ isLoading: loading }),
    }
}))
export const useListAction = () => useListStore((state) => state.actions);
export const getListData = () => useListStore.getState();