import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import Header from "@pages/workList/Header";
import {menuList, menuListType} from "@layout/menu";
import classNames from "classnames";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import { memo } from "@tanstack/react-table";
import { getUserData } from "@store/user/store";

export default function MobileLayout({child}:{child?:JSX.Element}) {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {userData} = getUserData();

    /* 사용자 그룹  
        1: 전체관리자
        2: 의사
        3: 센터
        4: 병원
        5: 영업담당자
    */
    const getAccessibleMenu = (groupIdx: number|undefined, menuList: menuListType[]): menuListType[] => {
        const accessibleIds = (() => {
            switch (groupIdx) {
                case 1:
                    return [
                        "reading", "payment","hospital", "doctor", "center", "adminhospital", "fee", "commission", "user", "statistics",
                         "s_hospital", "s_doctor", "s_center", "s_sales"
                    ];
                case 2:
                    return ["doctor","s_doctor"];
                case 3:
                    return ["reading", "payment","hospital" ,"doctor", "center", "adminhospital", "commission", , "fee", "s_center", ];
                case 4:
                    return ["reading", "payment", "s_hospital", "hospital",];
                case 5:
                    return ["s_sales"];
                default:
                    return [];
            }
        })();
    
        const filterMenu = (menu: menuListType[]): menuListType[] => {
            return menu.map((item) => {
                const isAccessible = accessibleIds.includes(item.id);
                const filteredChildren = item.children ? filterMenu(item.children) : undefined; // childern도 accessibleIds 검사
                const isAnyChildEnabled = filteredChildren?.some(child => !child.disabled) || false; // childern내에 arrow 있으면 부모도 동기화
                return {    
                    ...item,
                    url: filteredChildren && isAnyChildEnabled? filteredChildren.find(v => !v.disabled)?.url || item.url: item.url,
                    disabled: filteredChildren? (!isAccessible && !isAnyChildEnabled) :!isAccessible ,
                    children: filteredChildren,
                };
            });
        };
    
        return filterMenu(menuList);
    };
    const memoizedMenuList = useMemo(() => {
        const accessibleMenuList = getAccessibleMenu(userData?.group_idx, menuList);
        return accessibleMenuList;
    }, [userData]);

    const handleMenuNavigation = useCallback((item:menuListType) => {
        return (e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            navigate(item.url); // 페이지 이동
        };
    }, [navigate]);
    // const [isVisible, setIsVisible] = useState<boolean>(true);
    // useEffect(() => {
    //     setIsVisible(false); // Fade-out
    //     const timeout = setTimeout(() => setIsVisible(true), 300); // Fade-in
    //     return () => clearTimeout(timeout);
    // }, [pathname]);
    return (
        <div className="layout_wrap">
            <Header/>
            <div className={`contents_wrap`} style={{
                background: '#f6f7fb',
                overflow: 'hidden',
                gridColumn: '1 / 3',
                gridRow: '2'
            }}>
                {child?child:<Outlet/>}
            </div>
        </div>

    )
}
