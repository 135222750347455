import { FaBuilding, FaFileInvoiceDollar, FaCreditCard, FaList, FaHospital, FaChartBar, FaFileMedicalAlt, FaCalculator, FaUserCog, FaStethoscope, FaFileUpload } from "react-icons/fa";
import { RiHospitalFill } from "react-icons/ri";
import { MdBusinessCenter } from "react-icons/md";
import { IconType } from "react-icons";
import { getUserData } from "@store/user/store";

export type menuListType = {
    id: string;
    url: string;
    icon: IconType;
    name: string;
    disabled: boolean;
    children?: menuListType[];
};

export const menuList: menuListType[] = [
    { id: "reading", url: "/reading", icon: FaList, name: "의뢰검사조회", disabled: false },
    { id: "payment", url: "/payment", icon: FaFileInvoiceDollar, name: "정산조회", disabled: false,
        children: [
            { id: "hospital", url: "/payment/hospital", icon: FaHospital, name: "병원 정산", disabled: false },
            { id: "doctor", url: "/payment/doctor", icon: FaStethoscope, name: "판독의 정산", disabled: false },
        ],
    },
    { id: "center", url: "/manage/center", icon: FaBuilding, name: "센터 관리", disabled: false },
    { id: "adminhospital", url: "/manage/hospital", icon: FaHospital, name: "병원 관리", disabled: false },
    { id: "fee", url: "/manage/fee", icon: FaFileMedicalAlt, name: "판독료 관리", disabled: false },
    { id: "commission", url: "/manage/commission", icon: FaCalculator, name: "수수료 관리", disabled: false },
    { id: "user", url: "/manage/user", icon: FaUserCog, name: "사용자 관리", disabled: false },
    {
        id: "statistics",
        url: "/statistics",
        icon: FaChartBar,
        name: "통계 현황",
        disabled: false,
        children: [
            { id: "s_hospital", url: "/statistics/hospital", icon: FaHospital, name: "병원통계", disabled: false },
            { id: "s_doctor", url: "/statistics/doctor", icon: FaStethoscope, name: "판독의통계", disabled: false },
            { id: "s_center", url: "/statistics/center", icon: RiHospitalFill, name: "판독센터통계", disabled: false },
            { id: "s_sales", url: "/statistics/sales", icon: MdBusinessCenter, name: "영업담당자통계", disabled: false },
        ],
    },
    { id: "upload", url: "/upload", icon: FaFileUpload, name: "의료기록지 업로드", disabled: false },

];
